.contest-info-form {
    margin-top: 5vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
}

.alert {
    margin-top: 10vh;
    max-width: 80vw;
}

.grid-input {
    margin-top: 4vh;
}

.form-input {
    width: 60vw;
}

.tabs {
    margin-top: 8vh;
    margin-bottom: 6vh;
}

.submit-button {
    margin-top: 3vh;
    margin-bottom: 3vh;
}

.modal {
    margin-top: 30vh;
}

.modal-body {
    align-self: center;
}

.acknowledgement {
    font-size: x-small;
}