.payment-page {
    margin-top: 5vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
}

.alert {
    margin-top: 10vh;
    max-width: 80vw;
}

.tabs {
    margin-top: 8vh;
    margin-bottom: 6vh;
}

.payment-note {
    width: 80vw;
    font-size: large;
    text-align: left;
}

.credit-card-payment-form {
    padding-top: 5vh;
}

.payment-button {
    margin-top: 3vh;
}

.payment-note-snappay {
    padding-top: 3vh;
}

.bold {
    font-weight: 900;
}

.qr-code {
    margin-top: 5vh;
    height: 250px !important;
    width: 250px !important;
}